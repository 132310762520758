let accordionToggleArrow = require("url:../../assets/icons/toggle-arrow.svg");

class AccordionComponent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		let style = document.createElement("style");
		style.textContent = `
		.accordion-item {
			width: 100%;
			padding: 15px 0;
			border-bottom: .5px solid var(--primary-color);
		}

		.accordion-item:hover {
			cursor: pointer;
		}

		.accordion-item__wrapper {
			width: calc(100% - 50px);
			height: 40px;
			padding: 0 25px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			text-align: center;
		}

		@media (min-width: 768px) {
			.accordion-item__wrapper {
				width: calc(100% - 100px);
				height: auto;
				padding: 20px 50px;
			}
		}

		@media (min-width: 1440px) {
			.accordion-item__wrapper {
				width: calc(100% - 70px);
				padding: 20px 35px;
			}
		}

		.accordion-item__inverse-wrapper {
			flex-direction: row-reverse;	
		}

		.accordion-item__title {
			display: flex;
			justify-content: center;
		}

		.accordion-item__number {
			margin-left: 10px;
			font: 400 15px var(--aeonik-font);
		}

		@media (min-width: 960px) {
			.accordion-item__number {
			font: 400 28px var(--aeonik-font);
			}
		}

		@media (min-width: 1440px) {
			.accordion-item__number {
			font: 400 50px var(--aeonik-font);
			}
		}

		.accordion-item__toggle {
			transform: rotate(180deg);
			transition: transform .4s ease;
		}

		.accordion-item__toggle.active {
			transform: rotate(0deg);
		}

		.accordion-item__content {
			width: calc(100% - 50px);
			padding: 0 25px;
			display: none;
			overflow: hidden;
		}

		@media (min-width: 768px) {
            .accordion-item__content {
				width: calc(100% - 100px);
				padding: 0 50px;
			}
        }

		@media (min-width: 1440px) {
            .accordion-item__content {
				width: calc(100% - 70px);
				padding: 0 35px;
			}
        }

		.accordion-item__content.deactive {
			display: inline-block;
			max-height: 0px;
			animation: disappear .6s linear;
		}

		.accordion-item__content.active {
			display: inline-block;
			max-height: 2000px;
			animation: appear .6s linear;
		}

		@keyframes appear {
			0% {
				filter: blur(5px);
				opacity: 0.6;
				max-height: 0px;
			}
			100% {
				filter: blur(0px);
				opacity: 1;
				max-height: 2000px;
			}
		}

		@keyframes disappear {
			0% {
				filter: blur(0px);
				opacity: 1;
				max-height: 2000px;
			}
			100% {
				filter: blur(5px);
				opacity: 0.6;
				max-height: 0px;
			}
		}
        `;

		this.render();
		this.shadow.appendChild(style);
		this.addListeners();
	}

	render() {
		this.shadow.innerHTML = `
        <div class="accordion-item">
			<div class="accordion-item__wrapper ${
				this.hasAttribute("inverse")
					? "accordion-item__inverse-wrapper"
					: ""
			}">
				<div class="accordion-item__title">
            		<title-comp variant="accordion no-border" scheme="dark">${this.getAttribute(
						"title" || ""
					)}</title-comp>
					<span class="accordion-item__number">${this.getAttribute("number")}</span>
				</div>
				<img class="accordion-item__toggle ${
					this.hasAttribute("active") ? "active" : ""
				}" src="${accordionToggleArrow}">
			</div>
			<div class="accordion-item__content deactive ${
				this.hasAttribute("active") ? "active" : ""
			}">
				<text-comp variant="accordion" scheme="dark">${this.textContent}</text-comp>
			</div>
        </div>
        `;
	}

	addListeners() {
		const accordionItemEl = this.shadow.querySelector(".accordion-item");

		const itemContentEl = this.shadow.querySelector(
			".accordion-item__content"
		);

		const itemToggleEl = this.shadow.querySelector(
			".accordion-item__toggle"
		);

		itemContentEl.addEventListener(
			"animationend",
			() => {
				if (
					itemContentEl.classList.contains("deactive") &&
					itemContentEl.classList.contains("active")
				) {
					itemContentEl.classList.toggle("deactive");
				}
			},
			{ once: true }
		);

		accordionItemEl.addEventListener("click", () => {
			itemContentEl.classList.toggle("active");
			itemContentEl.classList.toggle("deactive");
			itemToggleEl.classList.toggle("active");
		});
	}
}
customElements.define("accordion-item", AccordionComponent);
