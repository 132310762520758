let toggleArrow = require("url:../../assets/icons/toggle-arrow.svg");
const firstProject = require("url:../../assets/images/first-project.png");
const secondProject = require("url:../../assets/images/second-project.png");
const thirdProject = require("url:../../assets/images/third-project.png");
const fourthProject = require("url:../../assets/images/fourth-project.png");
const fifthProject = require("url:../../assets/images/fifth-project.png");

class ProjectsListComponent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		let style = document.createElement("style");
		style.textContent = `
		.projects-list {
			display: none;
			width: 100%;
			border-bottom: .5px solid var(--primary-color);
		}

		.projects-list.active {
			display: inherit;
		}

		.projects-list__title {
            padding: 0 25px;
			display: flex;
			justify-content: space-between;
            align-items: center;
            border-bottom: .5px solid var(--primary-color);
		}


		@media (min-width: 768px) {
			.projects-list__title {
				padding: 0 50px;
			}
		}

		@media (min-width: 1440px) {
			.projects-list__title {
				padding: 0 35px;
			}
		}

        .projects-list__title:hover {
			cursor: pointer;
		}

		.projects-list__toggle {
			transform: rotate(180deg);
		}

        .projects-list__toggle.active {
			transform: rotate(0deg);
		}

		.projects-list__items {
			display: none;
			overflow: hidden;
		}

		.projects-list__items.active {
			display: block;
		}
        `;

		this.render();
		this.shadow.appendChild(style);
		this.addListeners();
	}

	render() {
		this.shadow.innerHTML = `
        <div class="projects-list ${
			this.hasAttribute("active") ? "active" : ""
		}">
            <div class="projects-list__title">
		    	    <title-comp variant="section no-x-padding no-border" scheme="dark">PROYECTOS ANTERIORES</title-comp>
		    	    <img class="projects-list__toggle ${
						this.hasAttribute("active") ? "active" : ""
					}" src="${toggleArrow}">	
		    </div>
		    <div class="projects-list__items ${
				this.hasAttribute("active") ? "active" : ""
			}">
                <project-item class="project-item" path="/project/las-heras-life" src="${firstProject}" title="LAS HERAS LIFE" address="Las Heras 1735, Florida" tags="Modelado, Interiorismo, Construcción">
                    Utilizamos metodología de construcción en seco o tradicional, también realizamos confección de muebles con revestimiento en microcemento.
                </project-item>
                <project-item class="project-item" path="/project/view-life" src="${secondProject}" title="VIEW LIFE" address="Valentín Virasoro 1288, Villa Crespo" tags="Modelado, Interiorismo, Construcción">
                    Utilizamos metodología de construcción en seco o tradicional, también realizamos confección de muebles con revestimiento en microcemento.
                </project-item>
                <project-item class="project-item" path="/project/maschwitz" src="${thirdProject}" title="MASCHWITZ" address="Las Heras 1735, Florida" tags="Modelado, Interiorismo, Construcción">
                    Utilizamos metodología de construcción en seco o tradicional, también realizamos confección de muebles con revestimiento en microcemento.
                </project-item>
                <project-item class="project-item" path="/project/guemes" src="${fourthProject}" title="CASA GÜEMES" address="Las Heras 1735, Florida" tags="Modelado, Interiorismo, Construcción">
                    Utilizamos metodología de construcción en seco o tradicional, también realizamos confección de muebles con revestimiento en microcemento.
                </project-item>
                <project-item class="project-item" path="/project/jonas-salk" src="${fifthProject}" title="JONAS SALK" address="Las Heras 1735, Florida" tags="Modelado, Interiorismo, Construcción">
                    Utilizamos metodología de construcción en seco o tradicional, también realizamos confección de muebles con revestimiento en microcemento.
                </project-item>
				<project-item class="project-item" path="/project/belzu" src="${fifthProject}" title="BELZU" address="Las Heras 1735, Florida" tags="Modelado, Interiorismo, Construcción">
					Utilizamos metodología de construcción en seco o tradicional, también realizamos confección de muebles con revestimiento en microcemento.
				</project-item>
            </div>
        </div>
        `;
	}

	addListeners() {
		const projectsListEl = this.shadow.querySelector(".projects-list");

		const listTitleEl = this.shadow.querySelector(".projects-list__title");

		const listItemsEl = this.shadow.querySelector(".projects-list__items");

		const toggleArrowEl = this.shadow.querySelector(
			".projects-list__toggle"
		);

		listTitleEl.addEventListener("click", () => {
			listItemsEl.classList.toggle("active");
			toggleArrowEl.classList.toggle("active");
		});

		window.addEventListener("hashchange", () => {
			if (window.location.hash == "#last-projects") {
				projectsListEl.classList.toggle("active");
				listItemsEl.classList.toggle("active");
				toggleArrowEl.classList.toggle("active");
			} else {
				projectsListEl.classList.remove("active");
				listItemsEl.classList.remove("active");
				toggleArrowEl.classList.remove("active");
			}
		});
	}
}
customElements.define("projects-list", ProjectsListComponent);
