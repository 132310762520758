class TextComponent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		let style = document.createElement("style");
		style.textContent = `
        .accordion {
            margin: 32px 0;
            font: 300 24px var(--aeonik-font);
        }

        @media (min-width: 960px) {
            .accordion {
                font: 300 36px var(--aeonik-font);
            }
        }

        @media (min-width: 1440px) {
            .accordion {
                font: 300 75px var(--aeonik-font);
            }
        }

        .menu-molde {
            font: 400 13px var(--aeonik-font);
        }

        @media (min-width: 768px) {
            .menu-molde {
                font-size: 15px;
            }
        }

        @media (min-width: 960px) {
            .menu-molde {
                font-size: 17px;
            }
        }

        @media (min-width: 1440px) {
            .menu-molde {
                font-size: 20px;
            }
        }

        .project {
            font: 400 11px var(--aeonik-font);
        }

        .card {
            font: 700 13px var(--aeonik-font);
        }

        @media (min-width: 768px) {
            .card {
                font-size: 17px;
            }
        }

        @media (min-width: 1440px) {
            .card {
                font-size: 20px;
            }
        }

        .card.border {
            border-bottom: 1px solid var(--secondary-color);
        }

        .footer {
            font: 500 20px var(--aeonik-font);
        }

        @media (min-width: 768px) {
            .footer {
                font-size: 24px;
            }
        }

        @media (min-width: 960px) {
            .footer {
                font-size: 32px;
            }
        }

        @media (min-width: 1280px) {
            .footer {
                font-size: 40px;
            }
        }

        @media (min-width: 1440px) {
            .footer {
                font-size: 50px;
            }
        }

        @media (min-width: 1850px) {
            .footer {
                font-size: 64px;
            }
        }
        
        .footer-details {
            font: 400 8px var(--aeonik-font);
        }

        @media (min-width: 768px) {
            .footer-details {
                margin: 20px 0;
                font-size: 12px;
            }
        }

        @media (min-width: 960px) {
            .footer-details {
                margin: 30px 0;
                font-size: 16px;
            }
        }

        @media (min-width: 1440px) {
            .footer-details {
                margin: 50px 0;
                font-size: 24px;
            }
        }

        .footer-details a {
            color: var(--secondary-color);
        }

        .footer-details.padding-applied {
            padding: 10px 30px;
        }

        .footer-details.copyright {
            margin-bottom: -13px;
        }

        @media (min-width: 768px) {
            .footer-details.copyright {
                display: none;
            }
        }

        .dark {
            color: var(--primary-color);
        }

        .light {
            color: var(--secondary-color);
        }

        .regular {
            font-weight: 400;
        }

        .maximized {
            font-size: 16px;
        }

        .no-margin {
            margin: 0;
        }

        .left-sided {
            text-align: left;
        }

        .middle-sided {
            text-align: center;
        }

        .right-sided {
            text-align: right;
        }
        `;

		this.render();
		this.shadow.appendChild(style);
	}

	render() {
		this.shadow.innerHTML = `
            <p class="
            ${this.getAttribute("variant")}
            ${this.getAttribute("scheme")}
            ">
            ${
				this.hasAttribute("contains-html")
					? this.innerHTML
					: this.textContent
			}
            </p>
        `;
	}
}
customElements.define("text-comp", TextComponent);
