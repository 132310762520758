const firstProjectBkgd = require("url:../../assets/images/first-project-card.png");

customElements.define(
	"first-project-page",
	class initProjectPage extends HTMLElement {
		connectedCallback() {
			this.render();
			this.addListeners();
		}

		render() {
			this.innerHTML = `
            <div class="container">
                <header-comp></header-comp>
				<div class="project-card">
                	<project-card src="${firstProjectBkgd}" title="LAS HERAS LIFE" address="Las Heras 1735, Florida, Bs As." tags="Proyecto, Dirección, Renders, Arquitectura, Construcción, Desarrollo, Instalaciones, Iluminación">
						Ubicado en un barrio arbolado y tranquilo, a sólo dos cuadras de Av. Maipú y cercano a Pte. Saavedra, el edificio busca armonizar el diseño y la naturaleza. Cuenta con departamentos de 1, 2 y 3 ambientes, de entre 40 y 70 m2, diseñados para tu disfrute, confort y bienestar. Proyecto co-construido con la Arq. Sofia Cupoli.
					</project-card>
				</div>
            </div>
        	`;

			const style = document.createElement("style");
			style.textContent = `

            .container {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
				user-select: none;
            }

			.loader-container {
                width: 100%;
				height: 100vh;
				display: flex;
				justify-content: center;
				align-items: center;
            }

			.project-card {
                width: 100%;
                height: 100vh;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
            }
        `;

			this.appendChild(style);
		}

		addListeners() {
			const bodyEl = document.querySelector("body");
			bodyEl.style.overflow = "hidden";
		}
	}
);
