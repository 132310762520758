const firstCollaborator = require("url:../../assets/images/collaborators/first-collaborator.png");
const secondCollaborator = require("url:../../assets/images/collaborators/second-collaborator.png");
const thirdCollaborator = require("url:../../assets/images/collaborators/third-collaborator.png");
const fourthCollaborator = require("url:../../assets/images/collaborators/fourth-collaborator.png");
const fifthCollaborator = require("url:../../assets/images/collaborators/fifth-collaborator.png");
const sixthCollaborator = require("url:../../assets/images/collaborators/sixth-collaborator.png");
const seventhCollaborator = require("url:../../assets/images/collaborators/seventh-collaborator.png");
const eighthCollaborator = require("url:../../assets/images/collaborators/eighth-collaborator.png");
const ninethCollaborator = require("url:../../assets/images/collaborators/nineth-collaborator.png");
const tenthCollaborator = require("url:../../assets/images/collaborators/tenth-collaborator.png");
const eleventhCollaborator = require("url:../../assets/images/collaborators/eleventh-collaborator.png");
const twelvethCollaborator = require("url:../../assets/images/collaborators/twelveth-collaborator.png");

class SliderComponent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		let style = document.createElement("style");
		style.textContent = `

        .slider {
            width: 100%;
            height: 70px;
            margin: 40px auto;
            overflow: hidden;
        }

        @media (min-width: 768px) {
            .slider {
                height: 80px;
            }
        }

        @media (min-width: 960px) {
            .slider {
                height: 100px;
            }
        }

        @media (min-width: 1280px) {
            .slider {
                height: 120px;
            }
        }

        @media (min-width: 1440px) {
            .slider {
                height: 170px;
            }
        }

        .slider__track {
            width: calc(175px * 22);
            height: 100%;
            display: flex;
            gap: 55px;
            -webkit-animation: mobile-scroll 30s linear infinite;
            animation: mobile-scroll 30s linear infinite;
        }

        @media (min-width: 768px) {
            .slider__track {
                width: calc(200px * 22);
                -webkit-animation: tablet-scroll 30s linear infinite;
                animation: tablet-scroll 30s linear infinite;    
            }
        }

        @media (min-width: 960px) {
            .slider__track {
                width: calc(300px * 22);
                -webkit-animation: desktop-scroll 30s linear infinite;
                animation: desktop-scroll 30s linear infinite;    
            }
        }

        @media (min-width: 1440px) {
            .slider__track {
                width: calc(400px * 22);
                -webkit-animation: desktop-scroll 30s linear infinite;
                animation: desktop-xl-scroll 30s linear infinite;    
            }
        }

        .slide {
            width: 175px;
            height: 80%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        @media (min-width: 768px) {
            .slide {
                height: 80%;
                width: 200px;
            }
        }

        @media (min-width: 960px) {
            .slide {
                width: 300px;
                height: 80%;
            }
        }

        @media (min-width: 1440px) {
            .slide {
                width: 400px;
            }
        }

        .slide img {
            width: auto;
            height: 100%;
        }


        @keyframes mobile-scroll {
            0% {
                -webkit-transform: translateX(0);
                transform: translateX(0);
            }
            100% {
                -webkit-transform: translateX(calc(-175px * 11 - 10px * 2));
                transform: translateX(calc(-175px * 11 - 10px * 2));
            }
        }

        @keyframes tablet-scroll {
            0% {
                -webkit-transform: translateX(0);
                transform: translateX(0);
            }
            100% {
                -webkit-transform: translateX(calc(-200px * 11 - 10px * 2));
                transform: translateX(calc(-200px * 11 - 10px * 2));
            }
        }

        @keyframes desktop-scroll {
            0% {
                -webkit-transform: translateX(0);
                transform: translateX(0);
            }
            100% {
                -webkit-transform: translateX(calc(-300px * 11 - 10px * 2));
                transform: translateX(calc(-300px * 11 - 10px * 2));
            }
        }


        @keyframes desktop-xl-scroll {
            0% {
                -webkit-transform: translateX(0);
                transform: translateX(0);
            }
            100% {
                -webkit-transform: translateX(calc(-400px * 11 - 10px * 2));
                transform: translateX(calc(-400px * 11 - 10px * 2));
            }
        }
        `;

		this.render();
		this.shadow.appendChild(style);
	}

	render() {
		this.shadow.innerHTML = `
            <div class="slider">
                <div class="slider__track">
                    <div class="slide">
                        <img src="${firstCollaborator}" alt="">
                    </div>
                    <div class="slide">
                        <img src="${secondCollaborator}" alt="">
                    </div>
                    <div class="slide">
                        <img src="${thirdCollaborator}" alt="">
                    </div>
                    <div class="slide">
                        <img src="${fourthCollaborator}" alt="">
                    </div>
                    <div class="slide">
                        <img src="${fifthCollaborator}" alt="">
                    </div>
                    <div class="slide">
                        <img src="${sixthCollaborator}" alt="">
                    </div>
                    <div class="slide">
                        <img src="${seventhCollaborator}" alt="">
                    </div>
                    <div class="slide">
                        <img src="${eighthCollaborator}" alt="">
                    </div>
                    <div class="slide">
                        <img src="${ninethCollaborator}" alt="">
                    </div>
                    <div class="slide">
                        <img src="${twelvethCollaborator}" alt="">
                    </div>
                    <div class="slide">
                        <img src="${eleventhCollaborator}" alt="">
                    </div>

                    <div class="slide">
                        <img src="${firstCollaborator}" alt="">
                    </div>
                    <div class="slide">
                        <img src="${secondCollaborator}" alt="">
                    </div>
                    <div class="slide">
                        <img src="${thirdCollaborator}" alt="">
                    </div>
                    <div class="slide">
                        <img src="${fourthCollaborator}" alt="">
                    </div>
                    <div class="slide">
                        <img src="${fifthCollaborator}" alt="">
                    </div>
                    <div class="slide">
                        <img src="${sixthCollaborator}" alt="">
                    </div>
                    <div class="slide">
                        <img src="${seventhCollaborator}" alt="">
                    </div>
                    <div class="slide">
                        <img src="${eighthCollaborator}" alt="">
                    </div>
                    <div class="slide">
                        <img src="${ninethCollaborator}" alt="">
                    </div>
                    <div class="slide">
                        <img src="${twelvethCollaborator}" alt="">
                    </div>
                    <div class="slide">
                        <img src="${eleventhCollaborator}" alt="">
                    </div>
                </div>
            </div>
        `;
	}
}
customElements.define("slider-comp", SliderComponent);
