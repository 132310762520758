const aboutUsImage = require("url:../../assets/images/about-us-image.png");
const croppedAboutUsImage = require("url:../../assets/images/cropped-about-us-image.png");

const whiteLinkedInLogo = require("url:../../assets/icons/linkedin-white.svg");
const whiteBehanceLogo = require("url:../../assets/icons/behance-white.svg");
const whiteInstagramLogo = require("url:../../assets/icons/instagram-white.svg");

customElements.define(
	"home-page",
	class initHomePage extends HTMLElement {
		connectedCallback() {
			this.render();
			this.addListeners();
		}

		render() {
			this.innerHTML = `
            <div class="container">
				<div class="loader-container">
					<loader-comp class="loader">
					</loader-comp>
				</div>

				<header-comp class="header"></header-comp>

				<div id="hero" class="hero">
					<hero-content class="hidden">
					</hero-content>
				</div>

				<div id="services" class="services">
					<title-comp class="hidden" variant="section" scheme="dark">SERVICIOS</title-comp>
					<div class="services__accordion">
						<accordion-item class="hidden" title="Diseño y desarrollo" number="01" active>Elaboramos anteproyectos y proyectos con estudios preliminares destinando el esfuerzo a encontrar la propuesta que más se adapte a las exigencias del cliente. Realizamos documentación de obra, pliegos técnicos y asesoramiento técnico en obra.</accordion-item>
						<accordion-item class="hidden" title="Construcción" number="02" inverse>Construimos obras de arquitectura como viviendas unifamiliares, edificio de oficinas, locales, obras “llaves en mano”, refacciones, ampliaciones y diseño de interiores. Dedicando igual atención a los pequeños y grandes detalles de nuestras obras.</accordion-item>
						<accordion-item class="hidden" title="Confección muebles" number="03">Nos especializamos en el diseño y confección de mobiliario en estructura de acero con Sistema Steel Frame y revestimiento en microcemento alisado. \nBuscando satisfacer las exigencias de nuestros clientes.</accordion-item>
					</div>
				</div>

				<div id="projects" class="projects">
					<title-comp class="hidden" variant="section" scheme="dark">PROYECTOS</title-comp>
					<projects-blocks class="hidden"></projects-blocks>
				</div>
				<div id="last-projects" class="last-projects">
					<projects-list></projects-list>					
				</div>

				<div id="about-us" class="about-us">
					<title-comp class="hidden" variant="section" scheme="light">NOSOTROS</title-comp>
					<div class="about-us__content">
						<div class="about-us__first-section"> 
							<subtitle-comp class="hidden" variant="section hide-mobile" scheme="light" contains-html>
								Generamos un ambiente de equipo <u>empático</u> y <u>emprendedor</u>.
							</subtitle-comp>
							<subtitle-comp class="hidden" variant="section hide-desktop" scheme="light">
								Nuestro equipo trabaja en un ambiente adecuado con motivaciones
							</subtitle-comp>
							<div class="first-section__description">
								<div class="first-section__first-paragraph">							
									<description-comp class="hidden" variant="section" scheme="light">
										En Molde nos dedicamos al proyecto, dirección y desarrollo de conceptos y soluciones arquitectónicas. Adoptamos un enfoque serio y flexible de nuestro trabajo, con años de experiencia en la industria combinados con una perspectiva fresca e innovadora. Esto nos permite adaptar nuestros servicios a las necesidades específicas de cada cliente, aportando soluciones a medida que consiguen los mejores resultados.
									</description-comp>
								</div>
								<div class="first-section__second-paragraph">							
									<description-comp class="hidden" variant="section no-margin" scheme="light">
										Utilizamos metodología de construcción en seco o tradicional, también realizamos confección de muebles con revestimiento en microcemento.
									</description-comp>
									<description-comp class="hidden" variant="section no-margin" scheme="light">
										Nos gusta generar un ambiente ameno y motivador para que nuestro equipo alcance su máximo potencial. Creemos que la creatividad es el instrumento fundamental a la hora de pensar proyectos modernos, sólidos y conscientes del mundo que los rodea.
									</description-comp>
								</div>
							</div>

						</div>
						<div class="about-us__second-section">
							<img class="about-us__content-image no-cropped" src="${aboutUsImage}">
							<img class="about-us__content-image cropped" src="${croppedAboutUsImage}">
						</div>
					</div>
					<title-comp class="hidden" variant="section tiny" scheme="light">NUESTROS COLABORADORES</title-comp>
					<div class="about-us__colaborators">
						<slider-comp></slider-comp>
					</div>
				</div>
				<div class="footer">
					<div class="footer__content">
						<div class="content__left-side">
							<div class="content__details">
								<div class="details__first-section">
									<text-comp class="hidden" variant="footer-details" scheme="light">
									© 2023 MOLDE
									</text-comp>
									<text-comp class="hidden" variant="footer-details" scheme="light">
										TERMINOS Y CONDICIONES
									</text-comp>								
								</div>
								<div class="details__second-section">
									<text-comp class="hidden" variant="footer-details" scheme="light" contains-html>
										ALL RIGHT RESERVED. MADE BY <a href="https://sutil.studio" target="blank_">SUTIL.STUDIO</a>
									</text-comp>
								</div>
							</div>
						</div>
						<div class="content__right-side">
							<text-comp class="hidden" variant="footer-details right-sided copyright" scheme="light">
								© 2023 MOLDE
							</text-comp>
							<text-comp class="hidden" variant="footer left-sided no-margin" scheme="light">
								LUGONES 3444, PB, CABA
							</text-comp>
							<text-comp class="hidden" variant="footer regular left-sided no-margin" scheme="light">
								CEL: 11-3941-1116
							</text-comp>
							<text-comp class="hidden" variant="footer regular middle-sided no-margin" scheme="light">
								CONTACTO@ESTUDIOMOLDE.COM
							</text-comp>
							<text-comp class="hidden" variant="footer regular right-sided no-margin" scheme="light">
								@MOLDE__ARQ
							</text-comp>
							<text-comp class="hidden" variant="footer regular left-sided no-margin" scheme="light">
								ARQUITECTURA ADAPTABLE
							</text-comp>
							
							<div class="content__social-media">
                            	<a class="social-media__link" href="https://www.linkedin.com/company/molde-arq/" target="_blank">
                            	    <img class="content__social-media-logo" src="${whiteLinkedInLogo}" alt="LinkeIn">
                            	</a>
                            	<a class="social-media__link" href="https://www.behance.net/moldearq" target="_blank">
                            		<img class="content__social-media-logo" src="${whiteBehanceLogo}" alt="Behance">
                            	</a>
                            	<a class="social-media__link" href="https://www.instagram.com/molde__arq/" target="_blank">
                            		<img class="content__social-media-logo" src="${whiteInstagramLogo}" alt="Instagram">
                            	</a>
                        	</div>
						</div>
					</div>
					<div class="footer__details max-vw">
						<text-comp class="hidden" variant="footer-details padding-applied" scheme="light">
							TERMINOS Y CONDICIONES
						</text-comp>
					</div>
					<div class="footer__details padding-applied">
						<text-comp class="hidden" variant="footer-details maximized no-margin" scheme="light">
							ALL RIGHT RESERVED.
						</text-comp>
						<text-comp class="hidden" variant="footer-details maximized no-margin" scheme="light" contains-html>
							MADE BY <a href="https://sutil.studio" target="blank_">SUTIL.STUDIO</a>
						</text-comp>
					</div>
				</div>
			</div>
        	`;

			const style = document.createElement("style");
			style.textContent = `

			.hidden {
				opacity: 0;
				filter: blur(5px);
				transform: translateY(-50px);
				transition: all .6s;
			}

			@media (prefers-reduced-motion) {
				.hidden {
					transition: none;
				}
			}

			.show {
				opacity: 1;
				filter: blur(0);
				transform: translateY(0);
			}

            .container {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
				user-select: none;
            }

			.loader-container {
				position: fixed;
				top: 0;
				bottom: 0;
                width: 100vw;
				height: 100vh;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: var(--main-bkgd-color);
				z-index: 9999;
				transition: all 2s;
            }

			.loader-container.hide {
				opacity: 0;
				filter: blur(55px);
			}

			.hero {
                width: 100%;
                height: 240vh;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
            }

			@media (min-width: 768px) {
				.hero {
					height: 300vh;
				}
			}

			@media (min-width: 960px) {
				.hero {
					height: 325vh;
				}
			}

			@media (min-width: 1440px) {
				.hero {
					height: 365vh;
				}
			}

			@media (min-width: 1850px) {
				.hero {
					height: 285vh;
				}
			}

			.services {
                width: 100%;
				min-height: 100vh;
                height: 100%;
				padding-top: 20vh;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
				background-color: var(--section-bkgd-color);
            }

			.services__accordion {
				width: 100%;
			}

			.projects {
                width: 100%;
				min-height: 100vh;
                height: 100vh;
				padding-top: 20vh;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
				background-color: var(--section-bkgd-color);
            }

			.last-projects {
				width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
				background-color: var(--section-bkgd-color);

			}

			.about-us {
                width: 100%;
				min-height: 100vh;
                height: 100%;
				paddin: 0 25px;
				padding-top: 20vh;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
				background-color: var(--bkgd-color);
            }

			.about-us__content {
				width: 100%;
				height: 100%;
				padding: 35px 25px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}

			@media (min-width: 768px) {
				.about-us__content {
					flex-direction: row;
					justify-content: space-between;
					align-items: start;
					padding: 0;
					padding-left: 50px;
					padding-top: 35px;
				}
			}

			@media (min-width: 960px) {
				.about-us__content {
					padding: 0;
					padding-left: 50px;
					padding-top: 70px;
				}
			}

			@media (min-width: 1440px) {
				.about-us__content {
					padding-left: 35px;
				}
			}

			@media (min-width: 768px) {				
				.about-us__first-section {
					width: 50%;
					height: 100%;
				}
			}

			@media (min-width: 960px) {
				.about-us__first-section {
					width: 75%;
				}
			}

			@media (min-width: 1280px) {				
				.about-us__first-section {
					width: 73%;
					height: 100%;
				}
			}

			@media (min-width: 1440px) {				
				.about-us__first-section {
					width: 73%;
					height: 100%;
				}
			}

			.first-section__description {
				padding-left: 4px;
			}

			@media (min-width: 768px) {
				.first-section__description {
					width: 100%;
					margin-top: 20px;
					display: flex;
					flex-direction: column;
					justify-content: space-evenly;
					align-items: start;
				}
			}

			@media (min-width: 1280px) {
				.first-section__description {
					width: 90%;
					margin-top: 40px;
					flex-direction: row;
					justify-content: space-between;
					gap: 25px;
				}
			}

			@media (min-width: 1440px) {
				.first-section__description {
					gap: 30px;
				}
			}

			@media (min-width: 768px) {
				.first-section__first-paragraph {
					width: 100%;
					padding-right: 25px;
				}
	
				.first-section__second-paragraph {
					width: calc(100vw - 75px);
					display: flex;
					justify-content: space-between;
					gap: 25px;
				}				
			}

			@media (min-width: 840px) {	
				.first-section__first-paragraph {
					padding-right: 0;
				}

				.first-section__second-paragraph {
					width: 100%;
					flex-direction: column;
					gap: 0;
				}				
			}

			@media (min-width: 960px) {
				.first-section__first-paragraph {
					width: 100%;
				}
	
				.first-section__second-paragraph {
					width: 100%;
					flex-direction: column;
				}				
			}

			@media (min-width: 1440px) {
				.first-section__first-paragraph {
					width: 50%;
				}
	
				.first-section__second-paragraph {
					width: 45%;
				}				
			}

			.about-us__second-section {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			@media (min-width: 768px) {
				.about-us__second-section {
					width: auto;
					height: auto;
					align-items: start;
				}
			}

			@media (min-width: 960px) {
				.about-us__second-section {
					justify-content: flex-end;
				}
			}

			@media (min-width: 1280px) {				
				.about-us__second-section {
					width: 27%;
					height: 100%;
					justify-content: flex-start;
					align-items: center;
				}
			}

			@media (min-width: 1440px) {				
				.about-us__second-section {
					width: auto;
					height: 100%;
					display: flex;
					justify-content: flex-start;
					align-items: center;
				}
			}

			.about-us__content-image {
				width: 90%;
				max-width: 375px;
				margin-bottom: 100px;
				border-radius: 22px; 
			}

			.about-us__content-image.cropped {
				display: none;
			}

			@media (min-width: 768px) {
				.about-us__content-image {
					width: 100%;
					max-width: 325px;
					justify-self: flex-end;
					border-radius: 22px 0 0 22px;
				}
			}

			@media (min-width: 960px) {
				.about-us__content-image {
					max-width: 90%;
				}
			}

			@media (min-width: 1280px) {
				.about-us__content-image {
					max-width: 100%;
				}
			}

			@media (min-width: 1440px) {
				.about-us__content-image.no-cropped {
					display: none;
				}

				.about-us__content-image.cropped {
					display: inherit;
					max-width: 490px;
					width: 100%;
					margin: 0;
					border-radius: 22px 0 0 22px;
				}
			}

			.about-us__colaborators {
				width: 100%;
				margin-bottom: 100px;
			}

			@media (min-width: 768px) {
				.about-us__colaborators {
					margin: 0;
				}	
			}

			.footer {
				width: 100%;
				height: 100%;
				padding: 0px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				border-top: 1px solid var(--section-bkgd-color);
			}

			@media (min-width: 768px) {
				.footer {
					flex-direction: row;
					padding: 0 35px;
					padding-right: 50px;
				}
			}

			@media (min-width: 1440px) {
				.footer {
					padding: 100px 35px;
					padding-right: 90px;
				}
			}

			.footer__content {
				width: 100%;
				max-width: 485px;
				padding: 50px 30px;
				display: flex;
				flex-direction: column;
			}

			@media (min-width: 768px) {
				.footer__content {
					max-width: none;
					flex-direction: row;
					justify-content: space-between;
					align-items: flex-start;
				}
			}

			.content__left-side {
				display: none;
			}

			@media (min-width: 768px) {
				.content__left-side {
					display: inherit;
					width: 40%;
				}
			}

			@media (min-width: 960px) {
				.content__left-side {
					width: 40%;
				}
			}

			@media (min-width: 1440px) {
				.content__left-side {
					width: 35%;
				}
			}

			.content__right-side {
				width: 100%;
			}

			@media (min-width: 768px) {
				.content__right-side {
					width: 60%;
				}
			}

			@media (min-width: 960px) {
				.content__right-side {
					width: 57%;
				}
			}

			.content__social-media {
				width: 100%;
				margin-top: 30px;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 15px;
			}

			.content__social-media-logo:hover {
				cursor: pointer;
				opacity: .8;
				transition: all .4s;
			}
	
			@media (min-width: 1280px) {
				.content__social-media-logo {
					width: 55px;
				}
			}
			
			@media (min-width: 1440px) {
				.content__social-media-logo {
					width: 70px;
				}
			}

			.social-media__link {
				text-decoration: none;
			}

			.content__details {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: flex-start;
			}

			.details__first-section {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 15px;
			}

			.footer__details {
				width: 100%;
				max-width: 485px;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}

			@media (min-width: 768px) {
				.footer__details {
					display: none; 
				}
			}

			.footer__details.max-vw {
				border-bottom: 1px solid var(--secondary-color);
			}

			.footer__details.padding-applied {
				padding: 30px;
			}

        `;

			this.appendChild(style);
		}

		addListeners() {
			const bodyEl = document.querySelector("body");
			const loaderEl: HTMLElement =
				this.querySelector(".loader-container");
			const headerEl: HTMLElement = this.querySelector(".header");

			window.addEventListener("load", () => {
				setTimeout(() => {
					bodyEl.style.overflow = "auto";
					loaderEl.classList.add("hide");
					headerEl.style.display = "inherit";
				}, 4000);
				setTimeout(() => {
					loaderEl.style.display = "none";
				}, 4600);
			});

			window.addEventListener("hashchange", () => {
				loaderEl.style.display = "none";
				bodyEl.style.overflow = "auto";
				if (
					window.location.hash == "#return-projects" ||
					window.location.hash == "#more-projects"
				) {
					var scrollPosition = Number(
						localStorage.getItem("scrollPosition")
					);
					if (scrollPosition !== null) {
						window.scrollTo(scrollPosition, scrollPosition);
						setTimeout(() => {
							localStorage.removeItem("scrollPosition");
						}, 100);
					}
				}
			});

			setTimeout(() => {
				bodyEl.style.overflow = "auto";
				loaderEl.classList.add("hide");
				headerEl.style.display = "inherit";
			}, 5000);
			setTimeout(() => {
				loaderEl.style.display = "none";
			}, 5600);

			const observer = new IntersectionObserver((entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						entry.target.classList.add("show");
					} else {
						entry.target.classList.remove("show");
					}
				});
			});

			const hiddenElements = this.querySelectorAll(".hidden");
			hiddenElements.forEach((el) => observer.observe(el));
		}
	}
);
