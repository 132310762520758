const animatedLoader = require("url:../../assets/molde-loader.svg");

class LoaderComponent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		let style = document.createElement("style");
		style.textContent = `
        .loader {
            width: 100%;
            min-height: 250px;
            border: none;
        }
        `;

		this.render();
		this.shadow.appendChild(style);
	}

	render() {
		this.shadow.innerHTML = `
                <iframe class="loader" src="${animatedLoader}"></iframe>
        `;
	}
}
customElements.define("loader-comp", LoaderComponent);
