const menuLogo = require("url:../../assets/menu-logo.svg");
const instagramLogo = require("url:../../assets/icons/instagram.svg");
const linkedInLogo = require("url:../../assets/icons/linkedin.svg");
const behanceLogo = require("url:../../assets/icons/behance.svg");

class menuComponent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		let style = document.createElement("style");
		style.textContent = `

        .menu-container {
            width: calc(100% - 50px);
            height: 100vh;
            padding: 0 25px;
            position: fixed;
            left: 0;
            right: 0;
            display: none;
            background-color: var(--tertiary-color);
        }
        
        @media (min-width: 768px) {
            .menu-container {
                width: calc(100% - 100px);
                padding: 0 50px;
            }
        }

        @media (min-width: 1440px) {
            .menu-container {
                width: calc(100% - 200px);
                padding: 0 100px;
            }
        }

        @media (min-width: 1920px) {
            .menu-container {
                width: calc(100% - 300px);
                padding: 0 150px;
            }
        }        

        .menu-container.active {
            z-index: 199;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            animation: forwardFade .3s linear;
        }

        @media (min-width: 768px) {
            .menu-container.active {
                flex-direction: row;
                justify-content: space-between;
            }
        }

        @media (min-width: 1280px) {
            .menu-container.active {
                justify-content: center;
            }
        }
        @media (min-width: 1440px) {
            .menu-container.active {
                justify-content: space-evenly;
            }
        }


        .menu-container.deactive {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            animation: backwardFade .3s linear;
        }

        @media (min-width: 768px) {
            .menu-container.deactive {
                flex-direction: row;
                justify-content: space-between;
            }
        }

        @media (min-width: 1440px) {
            .menu-container.deactive {
                justify-content: space-evenly;
            }
        }

        .menu__nav-links {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding-top: 15vh;
        }

        @media (min-width: 768px) {
            .menu__nav-links {
                height: 50vh;
                padding-top: 0;
                justify-content: space-evenly;
            }
        }

        @media (min-width: 960px) {
            .menu__nav-links {
                justify-content: space-between;
            }
        }

        @media (min-width: 1280px) {
            .menu__nav-links {
                width: 50%;
            }
        }

        @media (min-width: 1440px) {
            .menu__nav-links {
                width: 50%;
            }
        }

        .menu__second-section {
            width: 100%;
            max-width: 575px;
            height: 50vh;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
        }

        @media (min-width: 768px) {
            .menu__second-section {
                justify-content: space-around;
            }
        }

        @media (min-width: 960px) {
            .menu__second-section {
                justify-content: space-between;
            }
        }

         @media (min-width: 1280px) {
            .menu__second-section {
                width: 40%;
            }
        }

        @media (min-width: 1850px) {
            .menu__second-section {
                height: 51vh;
            }
        }

        .menu__footer {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        @media (min-width: 1280px) {
            .menu__footer {
                flex-direction: column;
                align-items: flex-start;
                gap: 25px;
            }
        }

        @media (min-width: 1440px) {            
            .menu__footer-logo {
                width: 210px;
                margin: 50px 0
            }
        }

        .menu__social-media {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 25px;
        }

        @media (min-width: 1280px) {

            .menu__social-media {
                width: 200px;
                justify-content: flex-start;
            }
        }

        .menu__social-media-logo {
            width: 37px;
        }

        .menu__social-media-logo:hover {
            cursor: pointer;
            opacity: .8;
            transition: all .4s;
        }

        @media (min-width: 960px) {
            .menu__social-media-logo {
                width: 40px;
            }
        }

        @media (min-width: 1440px) {
            .menu__social-media-logo {
                width: 60px;
            }
        }

        @media (min-width: 1850px) {
            .menu__social-media-logo {
                width: 80px;
            }
        }

        .social-media__link {
            text-decoration: none;
        }

        @keyframes forwardFade {
            0% {
                opacity: 0;
                filter: blur(15px);
                transform: scale(0.6);
            }
        
            100% {
                opacity: 1;
                transform: scale(1);
            }
        }

        @keyframes backwardFade {
            0% {
                opacity: 1;
                transform: scale(1);
            }
        
            100% {
                opacity: 0;
                filter: blur(15px);
                transform: scale(0.6);
            }
        }
        `;

		this.render();
		this.shadow.appendChild(style);
	}

	render() {
		this.shadow.innerHTML = `
            <div class="menu-container">
                <div class="menu__nav-links">
                    <link-comp class="nav-link" variant="menu fromLeft" scheme="dark" href="#hero" class="menu__link">inicio</link-comp>
                    <link-comp class="nav-link" variant="menu fromLeft" scheme="dark" href="#services" class="menu__link">servicios</link-comp>
                    <link-comp class="nav-link" variant="menu fromLeft" scheme="dark" href="#projects" class="menu__link">proyectos</link-comp>
                    <link-comp class="nav-link" variant="menu fromLeft" scheme="dark" href="#about-us" class="menu__link">nosotros</link-comp>
                </div>
                <div class="menu__second-section">
                    <div class="menu__info">
                        <text-comp variant="menu-molde no-margin" scheme="dark">
                        En Molde nos dedicamos al proyecto, dirección y desarrollo de conceptos y soluciones arquitectónicas. Adoptamos un enfoque serio y flexible de nuestro trabajo, con años de experiencia en la industria combinados con una perspectiva fresca e innovadora. Esto nos permite adaptar nuestros servicios a las necesidades específicas de cada cliente, aportando soluciones a medida que consiguen los mejores resultados.
                        </text-comp>
                    </div>

                    <div class="menu__footer">
                        <img class="menu__footer-logo" src="${menuLogo}" alt="MOLDE Arquitectura Adaptable">
                        <div class="menu__social-media">
                            <a class="social-media__link" href="https://www.linkedin.com/company/molde-arq/" target="_blank">
                                <img class="menu__social-media-logo" src="${linkedInLogo}" alt="LinkedIn">
                            </a>
                            <a class="social-media__link" href="https://www.behance.net/moldearq" target="_blank">
                            <img class="menu__social-media-logo" src="${behanceLogo}" alt="Behance">
                            </a>
                            <a class="social-media__link" href="https://www.instagram.com/molde__arq/" target="_blank">
                            <img class="menu__social-media-logo" src="${instagramLogo}" alt="Instagram">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        `;
	}
}
customElements.define("menu-comp", menuComponent);
