import "./router";

//? Pages
import "./pages/home";
import "./pages/projects/first-project";
import "./pages/projects/second-project";
import "./pages/projects/third-project";
import "./pages/projects/fourth-project";
import "./pages/projects/fifth-project";
import "./pages/projects/sixth-project";

//? Components
import "./components/loader";
import "./components/header";
import "./components/hero-content";
import "./components/menu";
import "./components/title";
import "./components/subtitle";
import "./components/text";
import "./components/description";
import "./components/link";
import "./components/accordion-item";
import "./components/projects-blocks";
import "./components/projects-list";
import "./components/project-item";
import "./components/project-card";
import "./components/slider";

function main() {
	const faviconEl = document.querySelector('link[rel="icon"]');
	const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
	mediaQuery.addEventListener("change", themeChange);

	function themeChange(event) {
		if (event.matches) {
			faviconEl.setAttribute("href", "dark-molde.ico");
		} else {
			faviconEl.setAttribute("href", "light-molde.ico");
		}
	}
}

main();
