const moldeLogo = require("url:../../assets/header-logo.svg");
const moldeLogoIso = require("url:../../assets/header-logo-iso.svg");
const burgerMenu = require("url:../../assets/icons/burger-menu.svg");
const crossIcon = require("url:../../assets/icons/cross-icon.svg");
class HeaderComponent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		let style = document.createElement("style");
		style.textContent = `
        .header {
            z-index: 200;
            position: fixed;
            left: 0;
            right: 0;
            width: calc(100% - 50px);
            padding: 25px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            mix-blend-mode: difference;
        }
        
        @media (min-width: 768px) {
            .header {
				width: calc(100% - 100px);
				padding: 30px 50px;
				transition: all .4s linear;
            }
        }

		@media (min-width: 1440px) {
            .header {
				width: calc(100% - 70px);
				padding: 35px;
            }
        }

		.header.iso {
			background-color: var(--primary-color);
			mix-blend-mode: normal;
			transition: all .4s linear;
		}

		.molde-logo {
			height: 45px;
		}

		@media (min-width: 1440px) {
			.molde-logo {
				height: 50px;
			}
		}

		@media (min-width: 1850px) {
			.molde-logo {
				height: 60px;
			}
		}

		.molde-logo:hover {
			cursor: pointer;
		}

		.burger-menu:hover {
			cursor: pointer;
		}

		.cross-menu {
			display: none;
		}

		.cross-menu:hover {
			cursor: pointer;
		}

        `;

		this.render();
		this.shadow.appendChild(style);
		this.addListeners();
	}

	render() {
		this.shadow.innerHTML = `
                <header class="header">
                    <a href="#hero"><img class="molde-logo" src="${moldeLogo}" logo="${moldeLogo}" iso=="${moldeLogoIso}"></a>
                    <img class="burger-menu" src="${burgerMenu}">
                    <img class="cross-menu" src="${crossIcon}">
                </header>
                <menu-comp class="menu"></menu-comp>
        `;
	}

	addListeners() {
		const bodyEl = document.querySelector("body");
		const heroSectionEl = document.querySelector(".hero");
		const servicesSectionEl = document.querySelector(".services");
		const projectsSectionEl = document.querySelector(".projects");
		const lastProjectsSectionEl = document.querySelector(".last-projects");

		const headerEl = this.shadow.querySelector(".header");
		const moldeLogoEl: HTMLElement =
			this.shadow.querySelector(".molde-logo");

		const burgerMenu: HTMLElement =
			this.shadow.querySelector(".burger-menu");

		const crossMenu: HTMLElement = this.shadow.querySelector(".cross-menu");

		const menuComponentEl = this.shadow.querySelector(".menu");
		const shadowMenuEl =
			menuComponentEl.shadowRoot.querySelector(".menu-container");
		const shadowMenuLinksEl =
			menuComponentEl.shadowRoot.querySelectorAll(".nav-link");

		window.addEventListener("scroll", () => {
			const headerOverlapsHero = this.elementsOverlap(
				moldeLogoEl,
				heroSectionEl
			);

			const headerOverlapsServices = this.elementsOverlap(
				moldeLogoEl,
				servicesSectionEl
			);

			const headerOverlapsProjects = this.elementsOverlap(
				moldeLogoEl,
				projectsSectionEl
			);

			const headerOverlapsLastProjects = this.elementsOverlap(
				moldeLogoEl,
				lastProjectsSectionEl
			);

			if (headerOverlapsHero) {
				moldeLogoEl.setAttribute("src", moldeLogo);
				headerEl.classList.remove("iso");
			} else if (
				headerOverlapsServices ||
				headerOverlapsProjects ||
				headerOverlapsLastProjects
			) {
				moldeLogoEl.setAttribute("src", moldeLogoIso);
				headerEl.classList.add("iso");
			}
		});

		burgerMenu.addEventListener("click", () => {
			bodyEl.style.overflowY = "hidden";
			shadowMenuEl.classList.add("active");
			burgerMenu.style.display = "none";
			crossMenu.style.display = "inherit";

			headerEl.classList.remove("iso");
			moldeLogoEl.setAttribute("src", moldeLogoIso);
		});

		crossMenu.addEventListener("click", () => {
			bodyEl.style.overflowY = "auto";
			shadowMenuEl.classList.add("deactive");
			shadowMenuEl.classList.remove("active");
			burgerMenu.style.display = "inherit";
			crossMenu.style.display = "none";
			shadowMenuEl.addEventListener(
				"animationend",
				() => {
					if (shadowMenuEl.classList.contains("deactive")) {
						shadowMenuEl.classList.remove("deactive");
					}
				},
				{ once: true }
			);
		});

		shadowMenuLinksEl.forEach((link) => {
			link.addEventListener("click", () => {
				bodyEl.style.overflowY = "auto";
				shadowMenuEl.classList.toggle("active");
				shadowMenuEl.classList.toggle("deactive");
				burgerMenu.style.display = "inherit";
				crossMenu.style.display = "none";
				shadowMenuEl.addEventListener(
					"animationend",
					() => {
						if (shadowMenuEl.classList.contains("deactive")) {
							shadowMenuEl.classList.remove("deactive");
						}
					},
					{ once: true }
				);
			});
		});
	}

	elementsOverlap(el1, el2) {
		const domRect1 = el1.getBoundingClientRect();
		const domRect2 = el2.getBoundingClientRect();

		const overlaps = !(
			domRect1.top > domRect2.bottom || domRect1.bottom < domRect2.top
		);

		return overlaps;
	}
}
customElements.define("header-comp", HeaderComponent);
