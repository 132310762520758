import { Router } from "@vaadin/router";

const cardArrow = require("url:../../assets/icons/card-arrow.svg");

class ProjectCardComponent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		let style = document.createElement("style");
		style.textContent = `            
                    .project-card {
                        width: calc(100vw - 50px);
                        height: 100vh;
                        padding: 0 25px;
                        left: 0;
                        right: 0;
                        position: absolute;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        background-image: url(${this.getAttribute("src")});
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        z-index: 200;
                    }
                    
                    @media (min-width: 768px) {
                        .project-card {
                            width: calc(100vw - 100px);
                            padding: 0 50px;
                        }
                    }

                    @media (min-width: 960px) {
                        .project-card {
                            align-items: end;
                        }
                    }
                    
                    @media (min-width: 1440px) {
                        .project-card {
                            width: calc(100% - 200px);
                            padding: 0 100px;
                        }
                    }
            
                    .project-card.active {
                        position: fixed;
                        display: flex;
                        animation: forwardFade 400ms ease forwards;
                    }
            
                    @media (min-width: 768px) {
                        .project-card.active {
                            flex-direction: row;
                            justify-content: space-between;
                        }
                    }
            
                    @media (min-width: 1440px) {
                        .project-card.active {
                            justify-content: space-evenly;
                        }
                    }

                    .project-card__wrapper {
                        width: calc(100% - 40px);
                        height: 100%;
                        max-width: 280px;
                        max-height: 440px;
                        padding: 40px 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;
                        align-items: center;
                        background-color: var(--card-bkgd-color);
                        border: none;
                        border-radius: 20px;
                    }

                    @media (min-width: 390px) {
                        .project-card__wrapper {
                            width: calc(100% - 80px);
                            padding: 60px 40px;
                            border-radius: 25px;
                        }
                    }

                    @media (min-width: 768px) {
                        .project-card__wrapper {
                            border-radius: 30px;
                            max-width: 320px;
                        }
                    }

                    @media (min-width: 960px) {
                        .project-card__wrapper {
                            max-width: 330px;
                            max-height: 500px;
                        }
                    }

                    @media (min-width: 1440px) {
                        .project-card__wrapper {
                            padding: 60px;
                            max-width: 380px;
                            max-height: 600px;
                        }
                    }

                    .project-card__return-arrow {
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                    }

                    .project-card__return-arrow:hover {
                        cursor: pointer;
                    }

                    .project-card__return-arrow a {
                        text-decoration: none;
                        display: flex;
                        align-items: center;
                        gap: 10px;
                    }

                    .project-card__address {
                        width: 100%;
                        text-align: center;
                        font: 400 27px var(--aeonik-font);
                        color: var(--secondary-color);
                    }
            
                    @media (min-width: 768px) {
                        .project-card__address {
                            font-size: 30px;
                        }
                    }
            
                    @media (min-width: 1400px) {
                        .project-card__address {
                            font-size: 34px;
                        }
                    }
                    
                    .project-card__data {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: flex-start;
                    }
            
                    @media (min-width: 768px) {
                        .project-card__data {
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                        }
                    }
            
                    @media (min-width: 960px) {
                        .project-card__data {
                            justify-content: space-evenly;
                        }
                    }

                    .project-card__content {
                        z-index: 99;
                        display: flex;
                        width: 100%;
                        min-height: 350px;
                        opacity: 0;
                        overflow: hidden;
                    }
            
                    .project-card__tags {
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        flex-wrap: wrap;
                        gap: 4px;
                    }
                    
                    .tags__pill-tag {
                        padding: 6px 12px;
                        border: 1px solid var(--secondary-color);
                        border-radius: 6px;
                    }

                    

                    .project-card__redirect {
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                    }

                    .project-card__link {
                        text-decoration: none;
                    }

                    .project-card__link:hover {
                        cursor: pointer;
                    }
            
                    @keyframes forwardFade {
                        0% {
                            opacity: 0;
                            transform: scale(0.6);
                        }
                    
                        100% {
                            opacity: 1;
                            transform: scale(1);
                        }
                    }
            
                    `;

		this.render();
		this.shadow.appendChild(style);
		this.addListeners();
		this.appendPillTags();
	}

	render() {
		this.shadow.innerHTML = `
                    <div class="project-card">
                        <div class="project-card__wrapper">
                            <div class="project-card__return-arrow">
                                <a href="#return-projects">
                                    <img src="${cardArrow}" alt="">
                                    <text-comp variant="card" scheme="light">Atrás</text-comp>
                                </a>
                            </div>
                            <div class="project-card__data">
                                <div>
                                    <title-comp variant="card" scheme="light">
                                        ${this.getAttribute("title")}
                                    </title-comp>
                                    <span class="project-card__address">
                                        ${this.getAttribute("address")}
                                    </span>
                                </div>

                                <description-comp variant="card" scheme="light">
                                ${this.textContent}
                                </description-comp>
                                <div>
                                    <title-comp variant="project tiny no-border no-top-margin" scheme="light">Qué hicimos</title-comp>
                                    <div class="project-card__tags"></div>
                                </div>
                                <div class="project-card__redirect">
                                    <a class="project-card__link" href="#more-projects">
                                        <text-comp variant="card border" scheme="light">Ver más proyectos</text-comp>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    `;
	}

	addListeners() {
		const returnArrowEl = this.shadow.querySelector(
			".project-card__return-arrow"
		);

		const projectCardLinkEl = this.shadow.querySelector(
			".project-card__link"
		);

		returnArrowEl.addEventListener("click", () => {
			Router.go("/");
		});

		projectCardLinkEl.addEventListener("click", () => {
			Router.go("/");
		});
	}

	appendPillTags() {
		const pillTags = this.shadow.querySelector(".project-card__tags");
		const tagsArr = this.getAttribute("tags").split(",");

		tagsArr.forEach((tag) => {
			let pillTag = document.createElement("span");
			pillTag.classList.add("tags__pill-tag");
			pillTag.innerHTML = `<text-comp variant="project no-margin" scheme="light">${tag}</text-comp>`;
			pillTags.appendChild(pillTag);
		});
	}
}
customElements.define("project-card", ProjectCardComponent);
