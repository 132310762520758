class LinkComponent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		let style = document.createElement("style");
		style.textContent = `

        .menu {
            width: 100%;
            display: table-caption;
            font: 400 64px var(--aeonik-font);
            line-height: 64px;
            text-decoration: none;
        }

        @media (min-width: 960px) {
            .menu {
                font-size: 80px;
                line-height: 64px;
            }
        }

        @media (min-width: 1280px) {
            .menu {
                font-size: 100px;
                line-height: 80px;
            }
        }

        @media (min-width: 1440px) {
            .menu {
                font-size: 120px;
            }
        }

        @media (min-width: 1920px) {
            .menu {
                font-size: 150px;
                line-height: 125px;
            }
        }

        .menu:after {
            display: block;
            content: '';
            border-bottom: solid 3px var(--primary-color);  
            transform: scaleX(0);  
            transition: transform .5s ease-in-out;
        }

        @media (min-width: 1280px) {
            .menu:after {
                border-bottom: solid 4px var(--primary-color);  
            }
        }
        
        @media (min-width: 1850px) {
            .menu:after {
                border-bottom: solid 5px var(--primary-color);  
            }
        }

        .menu:hover:after {
            transform: scaleX(1);
        }

        .menu.fromLeft:after { 
            transform-origin: 0% 50%; 
        }
        

        .card {
            font: 700 14px var(--aeonik-font);
        }

        .dark {
            color: var(--primary-color);
        }

        .light {
            color: var(--secondary-color);
        }
        `;

		this.render();
		this.shadow.appendChild(style);
	}

	render() {
		this.shadow.innerHTML = `
            <a class="
            ${this.getAttribute("variant")}
            ${this.getAttribute("scheme")}
            " href="${this.getAttribute("href")}">
            ${this.textContent}
            </a>
        `;
	}
}
customElements.define("link-comp", LinkComponent);
