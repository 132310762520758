class SubtitleComponent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		let style = document.createElement("style");
		style.textContent = `
        .section {
            font: 400 28px var(--aeonik-font);
            margin: 0;
        }

        @media (min-width: 960px) {
            .section {
                font-size: 50px;
            }
        }

        @media (min-width: 1280px) {
            .section {
                font-size: 75px;
            }
        }

        @media (min-width: 1440px) {
            .section {
                font-size: 80px;
            }
        }

        @media (min-width: 1920px) {
            .section {
                font-size: 120px;
            }
        }
        
        .card {
            font: 400 16px var(--aeonik-font);
        }

        .dark {
            color: var(--primary-color);
        }

        .light {
            color: var(--secondary-color);
        }

        .hide-mobile {
            display: none;
        }

        @media (min-width: 768px) {      
            .hide-mobile {
                display: inherit;
            }
            
            .hide-desktop {
                display: none;
            }
        }
        `;

		this.render();
		this.shadow.appendChild(style);
	}

	render() {
		this.shadow.innerHTML = `
            <h4 class="
            ${this.getAttribute("variant")}
            ${this.getAttribute("scheme")}
            ">
            ${
				this.hasAttribute("contains-html")
					? this.innerHTML
					: this.textContent
			}
            </h4>
        `;
	}
}
customElements.define("subtitle-comp", SubtitleComponent);
