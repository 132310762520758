const sixthProjectBkgd = require("url:../../assets/images/sixth-project-card.png");

customElements.define(
	"sixth-project-page",
	class initProjectPage extends HTMLElement {
		connectedCallback() {
			this.render();
			this.addListeners();
		}

		render() {
			this.innerHTML = `
            <div class="container">
                <header-comp></header-comp>
				<div class="project-card">
                <project-card src="${sixthProjectBkgd}" title="BELZU" address="Belzu 3850, Munro." tags="Proyecto, Dirección, Ampliación, Reforma, Arquitectura, Fachada, Construcción, Desarrollo, Iluminación">
					Ampliación y reforma de vivienda unifamiliar con sistema de construcción en seco, Steel Frame.
                </project-card>
				</div>
            </div>
        	`;

			const style = document.createElement("style");
			style.textContent = `

            .container {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
				user-select: none;
            }

			.loader-container {
                width: 100%;
				height: 100vh;
				display: flex;
				justify-content: center;
				align-items: center;
            }

			.project-card {
                width: 100%;
                height: 100vh;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
            }
        `;

			this.appendChild(style);
		}

		addListeners() {
			const bodyEl = document.querySelector("body");
			bodyEl.style.overflow = "hidden";
		}
	}
);
