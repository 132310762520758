import { Router } from "@vaadin/router";

const root = document.querySelector(".root");

const router = new Router(root);

router.setRoutes([
	{ path: "/", component: "home-page" },
	{ path: "/project/las-heras-life", component: "first-project-page" },
	{ path: "/project/view-life", component: "second-project-page" },
	{ path: "/project/maschwitz", component: "third-project-page" },
	{ path: "/project/guemes", component: "fourth-project-page" },
	{ path: "/project/jonas-salk", component: "fifth-project-page" },
	{ path: "/project/belzu", component: "sixth-project-page" },
]);
