// TODO: Agregar cards nuevas y reemplazar actualizadas.

const firstCard = require("url:../../assets/images/first-card.png");
const secondCard = require("url:../../assets/images/second-card.svg");
const thirdCard = require("url:../../assets/images/third-card.svg");
const fourthCard = require("url:../../assets/images/fourth-card.png");
const fifthCard = require("url:../../assets/images/fifth-card.svg");
const lastCard = require("url:../../assets/images/last-card.svg");

class MoldeContent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		let style = document.createElement("style");
		style.textContent = `
        .container {
            width: 100%;
            height: 235vh;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }

        @media (min-width: 768px) {
            .container {
                height: 300vh;
            }
        }

        @media (min-width: 960px) {
            .container {
                height: 325vh;
            }
        }

        @media (min-width: 1440px) {
            .container {
                height: 365vh;
            }
        }

        @media (min-width: 1850px) {
            .container {
                height: 280vh;
            }
        }

        .molde-title {
            z-index: 99;
            width: 100%;
            margin: 0 auto;
            text-align: center;
            top: 40vh;
            position: -webkit-sticky;
            position: sticky;
            font: 500 26vw var(--aeonik-font);
            color: var(--secondary-color);
        }

        @media (min-width: 768px) {
            .molde-title {
                top: 35vh;
                font-size: 27vw;
            }
        }

        @media (min-width: 960px) {
            .molde-title {
                top: 30vh;
            }
        }

        @media (min-width: 1024px) {
            .molde-title {
                top: 25vh;
            }
        }

        @media (min-width: 1280px) {
            .molde-title {
                top: 20vh;
            }
        }

        @media (min-width: 1440px) {
            .molde-title {
                font-size: 28vw;
            }
        }

        @media (min-width: 1920px) {
            .molde-title {
                top: 20vh;
            }
        }

        .cards {
            margin-top: 20vh;
            width: calc(100% - 50px);
            height: 70%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        @media (min-width: 530px) {
            .cards {
                margin-top: 13vh;
                width: 450px;
            }
        }

        @media (min-width: 768px) {
            .cards {
                margin-top: 10vh;
                width: 550px;
            }
        }

        @media (min-width: 960px) {
            .cards {
                width: 100%;
                margin-top: -10vh;
                justify-content: space-evenly;
            }
        }

        @media (min-width: 1440px) {
            .cards {
                width: 100%;
                margin-top: -10vh;
                justify-content: space-evenly;
            }
        }

        .cards__section {
            width: 100%;
            height: 25%;
            min-height: 430px;
            display: flex;
            justify-content: space-between;
        }

        @media (min-width: 960px) {
            .cards__section {
                width: 86%;
                gap: 32vw;
            }
        }


        @media (min-width: 1280px) {
            .cards__section {
                width: 87%;
                gap: 39vw;
            }
        }

        @media (min-width: 1440px) {
            .cards__section {
                width: 85%;
                gap: 42vw;
            }
        }

        @media (min-width: 1850px) {
            .cards__section {
                width: 84%;
                height: 22%;
                gap: 45vw;
            }
        }

        @media (min-width: 960px) {
            .first-section {
                justify-content: flex-start;
            }
    
            .second-section {
                justify-content: flex-end;
                align-items: center;
            }
    
            .third-section {
                justify-content: space-evenly;
            }
        }

        @media (min-width: 1280px) {
            .third-section {
                align-items: center;
            }
        }


        .first-card,
        .second-card,
        .third-card,
        .fourth-card,
        .fifth-card,
        .last-card {   
            z-index: 1;
            text-align: center;
            width: 50%;
        }

        .first-card img,
        .second-card img,
        .third-card img,
        .fourth-card img,
        .fifth-card img,
        .last-card img {
            width: 100%;
            max-width: 155px;
        }

        .inverse {
            align-self: flex-end;
        }

        .overlay {
            z-index: 100;
        }

        @media (min-width: 530px) {
            .first-card,
            .second-card,
            .third-card,
            .fourth-card,
            .fifth-card,
            .last-card {
                width: 200px;
                transform: scale(1.1);
            }
        }

        @media (min-width: 768px) {
            .first-card,
            .second-card,
            .third-card,
            .fourth-card,
            .fifth-card,
            .last-card {
                width: 220px;
                transform: scale(1.2);
            }

            .first-card img,
            .second-card img,
            .third-card img,
            .fourth-card img,
            .fifth-card img,
            .last-card img {
                transform: scale(1.15);
            }
        }

        @media (min-width: 960px) {
            .first-card img,
            .second-card img,
            .third-card img,
            .fourth-card img,
            .fifth-card img,
            .last-card img {
                transform: scale(1.3);
            }
        }

        @media (min-width: 1440px) {
            .first-card img,
            .second-card img,
            .third-card img,
            .fourth-card img,
            .fifth-card img,
            .last-card img {
                transform: scale(1.6);
            }
        }


        @media (min-width: 1850px) {
            .first-card img,
            .second-card img,
            .third-card img,
            .fourth-card img,
            .fifth-card img,
            .last-card img {
                transform: scale(2);
            }
        }
        `;

		this.render();
		this.shadow.appendChild(style);
		this.addListeners();
	}

	render() {
		this.shadow.innerHTML = `
            <div class="container">
                <div class="molde-title">
                    <p class="molde-title">MOLDE</p>
                </div>
                <div class="cards">
                    <div class="cards__section first-section">
                        <div class="first-card">
                            <img src="${firstCard}" alt="">
                        </div>
                        <div class="second-card inverse overlay">
                            <img src="${secondCard}" alt="">
                        </div>
                    </div>
                    <div class="cards__section second-section">                    
                        <div class="third-card">
                            <img src="${thirdCard}" alt="">
                        </div>
                        <div class="fourth-card inverse">
                            <img src="${fourthCard}" alt="">
                        </div>
                    </div>
                    <div class="cards__section third-section">                    
                        <div class="fifth-card overlay">
                            <img src="${fifthCard}" alt="">
                        </div>
                        <div class="last-card inverse overlay">
                            <img src="${lastCard}" alt="">
                        </div>
                    </div>
                </div>
            </div>
        `;
	}

	addListeners() {}
}
customElements.define("hero-content", MoldeContent);
