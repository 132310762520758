const secondProjectBkgd = require("url:../../assets/images/second-project-card.png");

customElements.define(
	"second-project-page",
	class initProjectPage extends HTMLElement {
		connectedCallback() {
			this.render();
			this.addListeners();
		}

		render() {
			this.innerHTML = `
            <div class="container">
                <header-comp></header-comp>
				<div class="project-card">
                <project-card src="${secondProjectBkgd}" title="VIEW LIFE" address="Valentín Virasoro 1288, Villa Crespo." tags="Proyecto, Dirección, Renders, Arquitectura, Construcción, Desarrollo, Instalaciones, Iluminación">
					Proyecto de vivienda multifamiliar en Villa Crespo. El edificio será construido en un barrio tranquilo y rodeado de verde, con vista a la plaza Gral. Benito Nazar. Cuenta con unidades de 2 ambientes, cocheras y terraza para disfrutar en familia.
				</project-card>
				</div>
            </div>
        	`;

			const style = document.createElement("style");
			style.textContent = `

            .container {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
				user-select: none;
            }

			.loader-container {
                width: 100%;
				height: 100vh;
				display: flex;
				justify-content: center;
				align-items: center;
            }

			.project-card {
                width: 100%;
                height: 100vh;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
            }
        `;

			this.appendChild(style);
		}

		addListeners() {
			const bodyEl = document.querySelector("body");
			bodyEl.style.overflow = "hidden";
		}
	}
);
