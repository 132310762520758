import { Router } from "@vaadin/router";
let projectToggleArrow = require("url:../../assets/icons/toggle-arrow.svg");

class ProjectItemComponent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		let style = document.createElement("style");
		style.textContent = `
		.project-item {
			width: 100%;
			border-bottom: .5px solid var(--primary-color);
		}

		@media (min-width: 768px) {
			.project-item {
				width: 200%;
				display: flex;
				-webkit-animation: scroll 40s linear infinite;
				animation: scroll 40s linear infinite;	
			}
		}

        .project-item__wrapper {
            width: 100%;
            padding: 25px 0;
            display: flex;
            flex-direction: column;
			user-select: none;
        }

        .project-item__wrapper.slider {
            display: none;
        }

		@media (min-width: 768px) {
			.project-item__wrapper {
				width: 100%;
				height: 350px;
				padding: 0px;
				flex-direction: row;
			}

			.project-item__wrapper.slider {
				display: flex;
			}

		}

		.project-item__frame {
			width: 100%;
			min-height: 350px;
			height: 100%;
			background-image: url(${this.getAttribute("src")});
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}

		@media (min-width: 768px) {
			.project-item__frame {
				max-width: 435px;
				max-height: 350px;
				border-radius: 30px;
			}
		}

		.project-item__frame:hover {
			cursor: pointer;
		}

		.project-item__content {
			z-index: 99;
			display: flex;
			width: calc(100% - 50px);
			min-height: 350px;
			padding: 0 25px;
			opacity: 0;
			overflow: hidden;
		}

		@media (min-width: 768px) {
			.project-item__content {
				width: calc(200vw - 100px);
				animation: backwardScroll 40s linear infinite;
			}
		}
		
		.project-item__content.active {
			opacity: 1;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			backdrop-filter: blur(10px);
			background-color: rgba(0, 0, 0, 0.5);
		}

		.project-item__content-tags {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 5px;
		}
		
		.content-tags__pill-tag {
			padding: 6px 12px;
			border: 1px solid var(--secondary-color);
			border-radius: 6px;
		}

		.project-item__data {
			width: calc(100% - 50px);
			height: 40px;
			padding: 0 25px;
            padding-top: 25px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			text-align: center;
		}

		@media (min-width: 768px) {
			.project-item__data {
				height: 100%;
				display: flex;
				flex-direction: column;
				padding: 0;
			}
		}

		@media (min-width: 960px) {
			.project-item__data {
				flex-direction: row;
				justify-content: space-evenly;
				align-items: center;
			}
		}

		.project-item__title {
			display: flex;
			justify-content: center;
            align-items: center;
		}

		.project-item__address {
			margin-left: 10px;
			font: 400 11px var(--aeonik-font);
		}

		@media (min-width: 768px) {
			.project-item__address {
				font-size: 16px;
			}
		}

		@media (min-width: 1280px) {
			.project-item__address {
				font-size: 35px;
			}
		}

		.project-item__toggle {
			z-index: 1;
            margin-right: 15px;
			transform: rotate(90deg);
		}

		@media (min-width: 768px) {	
			
			.project-item__toggle {
				width: 25px;
			}

			.project-item__toggle.hide {
				display: none;
			}
		}

		.project-item__toggle:hover {
			cursor: pointer;
		}

		@keyframes scroll {
            0% {
                -webkit-transform: translateX(0);
                transform: translateX(0);
            }
            100% {
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
            }
        }

		@keyframes backwardScroll {
            0% {
                -webkit-transform: translateX(0);
                transform: translateX(0);
            }
            100% {
                -webkit-transform: translateX(50%);
                transform: translateX(50%);
            }
        }
        `;

		this.render();
		this.shadow.appendChild(style);
		this.addListeners();
		this.appendPillTags();
	}

	render() {
		this.shadow.innerHTML = `
        <div class="project-item">
			<div class="project-item__wrapper">
                <div class="project-item__frame ${
					this.hasAttribute("active") ? "active" : ""
				}">
					<div class="project-item__content centered ${
						this.hasAttribute("active") ? "active" : ""
					}">
						<description-comp variant="project" scheme="light">
							${this.textContent}
						</description-comp>
						<title-comp variant="project tiny no-border" scheme="light">Qué hicimos</title-comp>
						<div class="project-item__content-tags"></div>
					</div>
                </div>
                <div class="project-item__data">
            	    <title-comp variant="project no-border" scheme="dark">
						${this.getAttribute("title")}
					</title-comp>
				    <span class="project-item__address">
						${this.getAttribute("address")}
					</span>
				    <img class="project-item__toggle" src="${projectToggleArrow}">
                </div>
			</div>
			<div class="project-item__wrapper slider">
                <div class="project-item__frame ${
					this.hasAttribute("active") ? "active" : ""
				}">
					<div class="project-item__content ${
						this.hasAttribute("active") ? "active" : ""
					}">
						<description-comp variant="project" scheme="light">
							${this.textContent}
						</description-comp>
						<title-comp variant="project tiny no-border" scheme="light">Qué hicimos</title-comp>
						<div class="project-item__content-tags"></div>
					</div>
                </div>
                <div class="project-item__data">
            	    <title-comp variant="project no-border" scheme="dark">
						${this.getAttribute("title")}
					</title-comp>
				    <span class="project-item__address">
						${this.getAttribute("address")}
					</span>
				    <img class="project-item__toggle" path="${this.getAttribute(
						"path"
					)}" src="${projectToggleArrow}">
                </div>
			</div>
        </div>
        `;
	}

	addListeners() {
		const itemWrapperEl = this.shadow.querySelectorAll(
			".project-item__wrapper"
		);

		const itemFrameEl = this.shadow.querySelector(".project-item__frame");

		const itemContentEl = this.shadow.querySelector(
			".project-item__content"
		);

		const itemToggleEl = this.shadow.querySelectorAll(
			".project-item__toggle"
		);

		itemFrameEl.addEventListener("click", () => {
			if (window.innerWidth < 768) {
				itemFrameEl.classList.toggle("active");
				itemContentEl.classList.toggle("active");
			}
		});

		itemWrapperEl.forEach((el) => {
			el.addEventListener("mouseenter", () => {
				if (window.innerWidth >= 768) {
					itemFrameEl.classList.toggle("active");
					itemContentEl.classList.toggle("active");
					itemToggleEl.forEach((el) => {
						el.classList.toggle("hide");
					});
				}
			});
		});

		itemWrapperEl.forEach((el) => {
			el.addEventListener("mouseleave", () => {
				if (window.innerWidth >= 768) {
					itemFrameEl.classList.toggle("active");
					itemContentEl.classList.toggle("active");
					itemToggleEl.forEach((el) => {
						el.classList.toggle("hide");
					});
				}
			});
		});

		itemContentEl.addEventListener("click", () => {
			if (window.innerWidth >= 768) {
				const path = this.getAttribute("path");
				Router.go(path);
			}
		});

		itemToggleEl.forEach((el) => {
			el.addEventListener("click", () => {
				const path = this.getAttribute("path");
				Router.go(path);
			});
		});
	}

	appendPillTags() {
		const pillTags = this.shadow.querySelector(
			".project-item__content-tags"
		);
		const tagsArr = this.getAttribute("tags").split(",");

		tagsArr.forEach((tag) => {
			let pillTag = document.createElement("span");
			pillTag.classList.add("content-tags__pill-tag");
			pillTag.innerHTML = `<text-comp variant="project no-margin" scheme="light">${tag}</text-comp>`;
			pillTags.appendChild(pillTag);
		});
	}
}
customElements.define("project-item", ProjectItemComponent);
