class TitleComponent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		let style = document.createElement("style");
		style.textContent = `

        .section {
            width: calc(100% - 50px);
            margin: 0;
            padding: 15px 25px;
            text-align: left;
            font: 700 24px var(--aeonik-font);
        }

        @media (min-width: 768px) {
            .section {
                width: calc(100% - 100px);
                padding: 15px 50px;
                font-size: 20px;
            }
        }

        @media (min-width: 1440px) {
            .section {
                width: calc(100% - 70px);
                padding: 15px 35px;
            }
        }

        @media (max-width: 767px) {
            .tiny {
                font-size: 12px;
            }
        }

        .section.tiny {
            margin-bottom: 20px;
            border: none;
        }

        @media (min-width: 768px) {
            .section.tiny {
                margin-top: 60px;
                border-bottom: .5px solid var(--secondary-color);
            }
        }

        .accordion {
            margin: 0;
            font: 400 30px var(--aeonik-font);
        }

        @media (min-width: 960px) {
            .accordion {
                font: 400 60px var(--aeonik-font);
            }
        }

        @media (min-width: 1440px) {
            .accordion {
                font: 400 120px var(--aeonik-font);
            }
        }

        .menu-molde {
            font: 500 32px var(--aeonik-font);
        }

        .project {
            font: 500 13px var(--aeonik-font);
            line-height: 16px;
        }

        .project.tiny {
            font-size: 13px;
            text-align: left;
        }


        @media (min-width: 768px) {
            .project {
                font-size: 26px;
                line-height: 30px;
            }

            .project.tiny {
                font: 400 15px var(--aeonik-font);
            }
        }

        @media (min-width: 1280px) {
            .project {
                font-size: 43px;
                line-height: 50px;
            }
        }

        .card {
            font: 500 29px var(--aeonik-font);
            margin: 0;
            padding: 10px 0;
        }

        @media (min-width: 1440px) {
            .card {
                font-size: 43px;
            }
        }

        .dark {
            color: var(--primary-color);
            border-bottom: .5px solid var(--primary-color);
        }

        .light {
            color: var(--secondary-color);
            border-bottom: .5px solid var(--secondary-color);
        }

        .no-x-padding {
            padding: 15px 0;
        }

        .no-margin {
            margin: 0;
        }

        .no-top-margin {
            margin-top: 0;
        }

        .no-border {
            border: none;
        }
        `;

		this.render();
		this.shadow.appendChild(style);
	}

	render() {
		this.shadow.innerHTML = `
            <h3 class="title
            ${this.getAttribute("variant")}
            ${this.getAttribute("scheme")}
            ">
            ${this.textContent}
            </h3>
        `;
	}
}
customElements.define("title-comp", TitleComponent);
