class DescriptionComponent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		let style = document.createElement("style");
		style.textContent = `

        .section {
            width: 100%;
            min-width: 40vw;
            margin: 60px 0;
            font: 400 15px var(--aeonik-font);
        }

        @media (min-width: 840px) {
            .section {
                min-width: 0;
                margin: 15px 0;
                font-size: 20px;
            }
        }
        
        .project {
            width: 67%;
            max-width: 525px;
            font: 400 15px var(--aeonik-font);
            line-height: 18px;
        }

        @media (min-width: 960px) {
            .project {
                width: 100%;
                max-width: 560px;
                font: 400 20px var(--aeonik-font);
                line-height: 28px;
            }
        }

        .card {
            width: 100%;
            text-align: left;
            font: 400 14px var(--aeonik-font);
        }

        @media (min-width: 960px) {
            .card {
                font-size: 17px;
            }
        }

        @media (min-width: 1440px) {
            .card {
                font-size: 20px;
            }
        }

        .dark {
            color: var(--primary-color);
        }

        .light {
            color: var(--secondary-color);
        }

        @media (min-width: 768px) and (max-width: 839px) {             
            .no-margin {
                margin: 0px;
            }
        }    
        `;

		this.render();
		this.shadow.appendChild(style);
	}

	render() {
		this.shadow.innerHTML = `
            <h3 class="
            ${this.getAttribute("variant")}
            ${this.getAttribute("scheme")}
            ">
            ${this.textContent}
            </h3>
        `;
	}
}
customElements.define("description-comp", DescriptionComponent);
