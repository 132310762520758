import { Router } from "@vaadin/router";

const moldeBlocks = require("url:../../assets/images/molde-blocks.svg");
const maskImage = require("url:../../assets/images/molde-logo-mask.png");

const firstBlockImage = require("url:../../assets/images/first-block-image.png");
const secondBlockImage = require("url:../../assets/images/second-block-image.png");
const thirdBlockImage = require("url:../../assets/images/third-block-image.png");
const fourthBlockImage = require("url:../../assets/images/fourth-block-image.png");
const fifthBlockImage = require("url:../../assets/images/fifth-block-image.png");
const sixthBlockImage = require("url:../../assets/images/sixth-block-image.png");

const plusIcon = require("url:../../assets/icons/plus-icon.svg");
class ProjectsBlocksComponent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		let style = document.createElement("style");
		style.textContent = `
                    
                    .projects-blocks {
                        width: 100%;
                        height: calc(100vh - 25vh);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;
                    }
                    
                    .blocks__mask {
                        width: 300px;
                        height: 280px;
                        background-image: url(${moldeBlocks});
                        background-size: contain;
                    }

                    @media (min-width: 1280px) {
                        .blocks__mask {
                            transform: scale(1.2);
                        }    
                    }

                    @media (min-width: 1440px) {
                        .blocks__mask {
                            transform: scale(1.7);
                        }    
                    }

                    @media (min-width: 1850px) {
                        .blocks__mask {
                            transform: scale(2);
                        }    
                    }

                    .fade {
                        transition: opacity .2s;
                    }

                    .project__block {
                        position: relative;
                        width: auto;
                        opacity: 0;
                    }

                    .project__block:hover {
                        cursor: pointer;
                    }

                    .project__block.active {
                        cursor: pointer;
                        opacity: 1;
                    }

                    .project__block-icon {
                        display: initial;
                        position: relative;
                        width: 25px;
                        height: 25px;
                    }

                    .project__block-icon.active {
                        display: none;
                    }

                    @media (min-width: 768px) {
                        .project__block-icon {                            
                            display: initial;
                            position: relative;
                            width: 25px;
                            height: 25px;   
                        }
                    }

                    .first-block {
                        width: 70%;
                        height: 39%;
                        top: 130px;
                        right: 20px;
                        transform: rotate(37deg);
                        z-index: 6;
                    }

                    @media (min-width: 768px) {
                        .first-block {
                            width: 25px;
                            height: 25px;
                            top: 160px;
                            right: -59px;
                            transform: rotate(0deg);
                        }
                    }

                    .first-block-icon {
                        top: 80px;
                        right: 150px;
                        z-index: 5;
                    }

                    @media (min-width: 768px) {
                        .first-block-icon {
                            top: 160px;
                            right: -30px;
                        }
                    }

                    .first-block.active {
                        width: 235px;
                        height: 212px;
                        top: 76px;
                        right: 39px;
                        transform: rotate(0deg);
                        z-index: 100;
                    }                    

                    .second-block {
                        width: 29%;
                        height: 80%;
                        bottom: 113px;
                        left: 95px;
                        z-index: 5;
                    }

                    @media (min-width: 768px) {
                        .second-block {
                            width: 25px;
                            height: 25px;
                            top: 80px;
                            left: 69px;
                        }
                    }

                    .second-block-icon {
                        bottom: 225px;
                        left: 35px;
                        z-index: 4;
                    }

                    @media (min-width: 768px) {
                        .second-block-icon {
                            top: 80px;
                            left: 40px;
                        }
                    }

                    .second-block.active {
                        width: 227px;
                        height: 383px;
                        bottom: 190px;
                        left: 73px;
                        z-index: 100;
                    }

                    @media (min-width: 768px) {
                        .second-block.active {
                            top: -77px;
                            left: 44px;
                        }
                    }

                    .third-block {
                        width: 34%;
                        height: 16%;
                        bottom: 258px;
                        left: -117px;
                        transform: rotate(28deg);
                        z-index: 4;
                    }

                    @media (min-width: 768px) {
                        .third-block {
                            width: 25px;
                            height: 25px;
                            bottom: -40px;
                            left: -81px;
                            transform: rotate(0deg);
                        }
                    }

                    .third-block-icon {
                        bottom: 270px;
                        right: 190px;
                        z-index: 3;
                    }

                    @media (min-width: 768px) {
                        .third-block-icon {
                            top: 40px;
                            right: 110px;
                        }    
                    }

                    .third-block.active {
                        width: 415px;
                        height: 133px;
                        bottom: 343px;
                        left: -51px;
                        transform: rotate(0deg);
                        z-index: 100;
                    }

                    @media (min-width: 768px) {
                        .third-block.active {
                            bottom: 30px;
                            left: -51px;
                        }
                    }

                    .fourth-block {
                        width: 35%;
                        height: 25%;
                        bottom: 450px;
                        left: 172px;
                        z-index: 3;
                    }

                    @media (min-width: 768px) {
                        .fourth-block {
                            width: 25px;
                            height: 25px;
                            bottom: -39px;
                            left: 33px;
                        }
                    }


                    .fourth-block-icon {
                        bottom: 460px;
                        left: 90px;
                        z-index: 2;
                    }

                    @media (min-width: 768px) {
                        .fourth-block-icon {
                            top: 38px;
                            left: 4px;
                        }
                    }

                    .fourth-block.active {
                        width: 415px;
                        height: 133px;
                        bottom: 339px;
                        left: -51px;
                        z-index: 100;
                    }

                    @media (min-width: 768px) {                        
                        .fourth-block.active {
                            bottom: 26px;
                            left: -51px;
                        }
                    }

                    .fifth-block {
                        width: 22%;
                        height: 42%;
                        bottom: 327px;
                        left: 32px;
                        z-index: 2;
                    }

                    @media (min-width: 768px) {
                        .fifth-block {
                            width: 25px;
                            height: 25px;
                            top: 105px;
                            left: -42px;
                        }
                    }

                    .fifth-block-icon {
                        bottom: 387px;
                        left: -17px;
                        z-index: 1;
                    }

                    @media (min-width: 768px) {
                        .fifth-block-icon {
                            top: 105px;
                            left: -71px;
                        }
                    }

                    .fifth-block.active {
                        width: 174px;
                        height: 255px;
                        bottom: 280px;
                        left: -45px;
                        z-index: 100;
                    }

                    @media (min-width: 768px) {                        
                        .fifth-block.active {
                            top: 61px;
                            left: -53px;
                        }
                    }

                    .sixth-block {
                        width: 21%;
                        height: 64%;
                        bottom: 269px;
                        left: 0px;
                        z-index: 1;
                    }

                    @media (min-width: 768px) {
                        .sixth-block {
                            width: 25px;
                            height: 25px;
                            top: 110px;
                            left: 255px;
                            transform: rotate(0deg);
                        }
                    }

                    .sixth-block-icon {
                        bottom: 390px;
                        left: 255px;
                        z-index: 0;
                    }

                    @media (min-width: 768px) {
                        .sixth-block-icon {
                            top: 110px;
                            left: 226px;
                        }    
                    }

                    .sixth-block.active {
                        width: 220px;
                        height: 323px;
                        bottom: 450px;
                        left: 125px;
                        z-index: 100;
                    }

                    @media (min-width: 768px) {
                        .sixth-block.active {
                            top: -15px;
                            left: 124px;
                        }                            
                    }
                    `;

		this.render();
		this.shadow.appendChild(style);
		this.addListeners();
	}

	render() {
		this.shadow.innerHTML = `
            <div class="projects-blocks">
                <div class="blocks__mask">
                    <img class="fade project__block first-block" path="/project/las-heras-life" src="${firstBlockImage}">
                    <img class="project__block-icon first-block-icon" src="${plusIcon}">
                    <img class="fade project__block second-block" path="/project/maschwitz" src="${secondBlockImage}">
                    <img class="project__block-icon second-block-icon" src="${plusIcon}">
                    <img class="fade project__block third-block" path="/project/jonas-salk" src="${thirdBlockImage}">
                    <img class="project__block-icon third-block-icon" src="${plusIcon}">
                    <img class="fade project__block fourth-block" path="/project/guemes" src="${fourthBlockImage}">
                    <img class="project__block-icon fourth-block-icon" src="${plusIcon}">
                    <img class="fade project__block fifth-block" path="/project/view-life" src="${fifthBlockImage}">
                    <img class="project__block-icon fifth-block-icon" src="${plusIcon}">
                    <img class="fade project__block sixth-block" path="/project/belzu" src="${sixthBlockImage}">
                    <img class="project__block-icon sixth-block-icon" src="${plusIcon}">
                </div>
            </div>
        `;
	}

	addListeners() {
		const projectBlocksEl = this.shadow.querySelectorAll(".project__block");

		const plusIconsEl = this.shadow.querySelectorAll(
			".project__block-icon"
		);

		projectBlocksEl.forEach((blockEl: HTMLElement) => {
			const activeBlockEvent = () => {
				localStorage.setItem("scrollPosition", `${window.scrollY}`);
				const path = blockEl.getAttribute("path");
				Router.go(path);
			};

			const blockEvent = () => {
				blockEl.classList.add("active");
				blockEl.addEventListener("click", activeBlockEvent);
				plusIconsEl.forEach((iconEl: HTMLElement) => {
					iconEl.classList.add("active");
				});
			};

			blockEl.addEventListener("click", blockEvent, {
				once: true,
			});

			blockEl.addEventListener("mouseleave", () => {
				blockEl.classList.remove("active");
				blockEl.removeEventListener("click", activeBlockEvent);
				blockEl.addEventListener("click", blockEvent);
				plusIconsEl.forEach(
					(iconEl: HTMLElement) => {
						iconEl.classList.remove("active");
					},
					{ once: true }
				);
			});
		});
	}
}
customElements.define("projects-blocks", ProjectsBlocksComponent);
